import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import {
  MAX_COMPETENCY_SLIDER_RANGE,
  MIN_COMPETENCY_SLIDER_RANGE
} from 'constants/constants';
import { Box, Grid, styled, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import MSwitch from '@components/@material-extend/MSwitch';
import useLocales from '@hooks/useLocales';
import { TeamCandidateTable } from './TeamCandidateTable';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface Data {
  comparePoints?: number[][]; // comparison data points
  labels: string[];
  points?: number[];
  candidateNames: string[];
  descriptions: string[];
}
interface Dataset {
  group: string;
  data: Data; // main data points
}

interface TeamChartProps {
  showTable?: boolean;
  setShowTable?: React.Dispatch<React.SetStateAction<boolean>>;
  setChartRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null> | undefined>
  >;
  setTableRef?: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<null> | undefined>
  >;
  datasetsByGroup: Dataset[];
  labels: string[];
  showLegend?: boolean;
  withTable?: boolean;
}

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%'
}));

const colors = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF6384',
  '#36A2EB'
];

const getColorForCandidate = (index: number) => colors[index % colors.length];

let XYIndexes: any = {};

export const TeamChart: React.FC<TeamChartProps> = ({
  showTable,
  setShowTable,
  setChartRef,
  setTableRef,
  datasetsByGroup,
  labels,
  showLegend = false,
  withTable = false
}) => {
  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const [, setCreated] = useState<any>({});
  const { t } = useLocales();

  useEffect(() => {
    if (setChartRef && chartRef.current) {
      for (let i = 0; i < 1; i++) {
        setChartRef(chartRef);
      }
    }
  }, [chartRef]);

  const datasets = datasetsByGroup
    .map((groupData) => {
      const {
        group,
        data: { comparePoints, labels, candidateNames, descriptions }
      } = groupData;
      // Ensure `comparePoints` is properly defined and has the expected number of items
      if (!comparePoints || comparePoints.length === 0) {
        console.warn(`Missing comparePoints data for group: ${group}`);
        return []; // Skip group if there's no comparePoints data
      }

      // Ensure the number of datasets corresponds to the number of candidates (length of comparePoints)
      const numberOfCandidates = comparePoints[0].length; // Get the number of candidates

      const candidateDatasets = [];

      // Create one dataset for each candidate
      for (
        let candidateIndex = 0;
        candidateIndex < numberOfCandidates;
        candidateIndex++
      ) {
        const candidatePoints = comparePoints.map(
          (pointsForLabel) => pointsForLabel[candidateIndex]
        );

        const candidateDataset = {
          label: `${
            candidateNames[candidateIndex] || `Candidate ${candidateIndex + 1}`
          }`,
          data: candidatePoints, // Points for this candidate across all labels
          backgroundColor: `${getColorForCandidate(candidateIndex)}40`, // Transparent background
          borderColor: getColorForCandidate(candidateIndex),
          borderWidth: 1,
          pointBackgroundColor: getColorForCandidate(candidateIndex),
          descriptions
        };

        candidateDatasets.push(candidateDataset); // Add the dataset for this candidate
      }

      return candidateDatasets;
    })
    .flat();

  const handleShowTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setShowTable) {
      setShowTable(event.target.checked);
    }
  };

  const drawTextAtIndex = (scale: any, index: string | number, text: any) => {
    const offset = 36;
    const r = scale.drawingArea + offset;
    const angle = scale.getIndexAngle(index) - Math.PI / 2;
    const x = scale.xCenter + Math.cos(angle) * r;
    const y = scale.yCenter + Math.sin(angle) * r;
    const { ctx } = scale;

    ctx.save();
    ctx.translate(x, y);
    ctx.textAlign = 'center';
    ctx.font = "600 10px 'Rubik'";
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = 'destination-over';

    const textArray = getLines(ctx, text, 75);
    let Xscore = 0;
    let Yscore = 0;
    if (index === '0') {
      ctx.fillText(`1 ${text}`, 0, 20);
      Xscore = -30;
      Yscore = -20;
    } else {
      textArray.map((item: string, i: number) => {
        let text = item;
        if (i === 0) {
          text = `${(Number(index) + 1).toString()} ${item}`;
        }
        ctx.fillText(
          text,
          labels.length / 2 < Number(index) ? -8 : 8,
          20 - textArray.length * 8 + i * 10
        );
        Xscore = labels.length / 2 < Number(index) ? -40 : -25;
        Yscore = 20 - textArray.length * 8 + i * 10 - 35 - textArray.length * 8;
      });
    }
    XYIndexes[index] = {
      x,
      y,
      scale,
      Xscore,
      Yscore,
      created: false
    };
    ctx.restore();
    if (canvasRef?.current) {
      const createdValue: any = {};
      mouseMove({ offsetX: 100, offsetY: 100 }, createdValue);
    }
  };

  const getLines = (ctx: any, text: any, maxWidth: any) => {
    const lines = [];
    if (text) {
      const words = text.split(' ');
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const { width } = ctx.measureText(`${currentLine} ${word}`);
        if (width < maxWidth) {
          currentLine += ` ${word}`;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
    }
    return lines;
  };

  const roundRect = (
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill = false,
    stroke = true
  ) => {
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  };

  const mouseMove = (e: any, createdValue: any) => {
    const valuesCanvas: any = Object.values(XYIndexes);
    for (let i = 0; i < valuesCanvas.length; i++) {
      if (!XYIndexes[i].created) {
        const element: any = valuesCanvas[i];
        const { x, y, scale } = element;
        const { ctx } = scale;

        ctx.beginPath();
        ctx.strokeStyle = '#6666662b';
        ctx.fillStyle = '#ffffff';
        ctx.shadowBlur = 3;
        ctx.shadowColor = '#6666662b';

        ctx.beginPath();
        ctx.shadowBlur = 0;
        ctx.shadowColor = 'none';
        ctx.fillStyle = '#666666';
        ctx.font = "400 12px 'Rubik'";

        ctx.closePath();
        createdValue[`${i}`] = true;
        XYIndexes[i].created = true;
      }
    }
  };

  const wrapText = (
    ctx: any,
    text: string,
    x: any,
    y: any,
    maxWidth: string | number,
    lineHeight: string | number
  ) => {
    const words = text.split(' ');
    let line = '';
    const lines = [];

    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const testWidth = ctx.measureText(testLine).width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = `${words[n]} `;
      } else {
        line = testLine;
      }
    }
    lines.push(line);
    return lines;
  };

  const touch = () => {
    const { canvas }: any = canvasRef.current as any;
    const createdValue: any = {};
    canvas.onmousemove = (e: any) => {
      const { offsetX, offsetY } = e;

      for (let i = 0; i < Object.values(XYIndexes).length; i++) {
        const element: any = Object.values(XYIndexes)[i];
        const { x, y, scale } = element;
        const { ctx } = scale;
        if (
          offsetX - x < 30 &&
          offsetX - x > -30 &&
          offsetY - y < 30 &&
          offsetY - y > -30 &&
          !createdValue[`${i}`]
        ) {
          const index = i;
          if (index !== -1) {
            const description = datasets[0].descriptions[index];
            const textArray = wrapText(ctx, description, x, y, 200, 20); // Wrap text

            // Calculate tooltip dimensions
            const tooltipWidth = 220; // Set a maximum width for the tooltip
            const tooltipHeight = 20 * textArray.length + 20; // Calculate height based on number of lines

            // Adjust position to prevent clipping
            const tooltipX = Math.min(x, canvas.clientWidth - tooltipWidth);
            const tooltipY = Math.min(y, canvas.height - tooltipHeight);

            // Draw the tooltip background
            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'; // Tooltip background color
            roundRect(ctx, tooltipX, tooltipY, tooltipWidth, tooltipHeight, 5); // Draw the tooltip background
            ctx.fill(); // Fill the background

            // Draw the wrapped text
            ctx.fillStyle = '#000'; // Tooltip text color
            for (let i = 0; i < textArray.length; i++) {
              ctx.fillText(textArray[i], tooltipX + 10, tooltipY + 20 + i * 20); // Draw each line
            }
            createdValue[`${i}`] = true;
          }
        } else if (
          createdValue[i] &&
          (offsetX - x >= 30 ||
            offsetX - x <= -30 ||
            offsetY - y >= 30 ||
            offsetY - y <= -30)
        ) {
          createdValue[`${i}`] = false;
          XYIndexes = {};
          setCreated({});
        }
      }
    };
  };

  return (
    <Grid
      item
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Wrapper
        ref={chartRef}
        onMouseEnter={() => {
          XYIndexes = {};
          setCreated({});
          touch();
        }}
        onMouseLeave={() => {
          XYIndexes = {};
          setCreated({});
        }}
      >
        <Radar
          ref={canvasRef}
          data={{
            labels,
            datasets
          }}
          options={{
            animation: {
              duration: 0
            },
            scales: {
              r: {
                suggestedMin: MIN_COMPETENCY_SLIDER_RANGE,
                suggestedMax: MAX_COMPETENCY_SLIDER_RANGE,
                pointLabels: {
                  font: {
                    size: 14
                  },
                  color: 'transparent'
                },
                ticks: {
                  display: false,
                  maxTicksLimit: 6
                },
                grid: {
                  color: '#828282'
                },
                angleLines: {
                  color: '#828282'
                }
              }
            },
            plugins: {
              legend: {
                display: showLegend
              },
              tooltip: {
                titleFont: {
                  size: 16
                },
                bodyFont: {
                  size: 16
                }
              }
            }
          }}
          plugins={[
            {
              id: 'custom_labels',
              afterDraw: (chart: any) => {
                const scale = chart.scales.r;
                chart.data.labels.forEach((label: string, index: number) => {
                  drawTextAtIndex(scale, index, label);
                });
              }
            }
          ]}
        />
      </Wrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
        <MSwitch
          checked={showTable}
          onChange={handleShowTable}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography>{t('View scores')}</Typography>
      </Box>
      <Box
        sx={{
          display: withTable && showTable ? 'flex' : 'none',
          width: '100%'
        }}
      >
        <TeamCandidateTable
          setTableRef={setTableRef}
          labels={labels}
          datasets={datasets}
        />
      </Box>
    </Grid>
  );
};
