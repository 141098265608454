import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  TextField,
  Chip,
  Grid,
  Stack,
  Typography
} from '@material-ui/core';

import { getPlaceholders } from 'constants/text/placeholders';
import CandidateCardContainer from 'components/containers/CandidateCardContainer';
import CandidateModalContainer from 'components/containers/CandidateModalContainer';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';
import { updateDataByUrl } from 'requests';
import useLocales from 'hooks/useLocales';
import { CardWrapper } from 'styled';
import { Title1 } from 'components/text/title';
import MButton from 'components/new-designer/button/MButton';
import EmptyContent from 'components/EmptyContent';

interface SkillSet {
  title: string;
  data: string[];
}

interface Skills {
  hard_skills: SkillSet;
  soft_skills: SkillSet;
}

const CustomAutocomplete = styled(Autocomplete)((props: any) => ({
  '.MuiChip-root': {
    background: !props?.eventInterview ? '#9B5FDA' : 'rgba(93, 56, 137, 0.11)',
    borderRadius: '30px',
    padding: '10px 20px',
    height: 'auto',
    fontFamily: 'Rubik',
    fontWeight: 700,
    fontSize: '14px',
    color: !props?.eventInterview ? '#FFFFFF' : '#562C82',

    '& svg': props?.eventInterview && {
      background: 'url(/static/icons/e-remove.svg) no-repeat right center',
      width: 'max-content',
      '& g': {
        display: 'none'
      }
    }
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none'
  }
}));

export const CandidateSkillsCard = ({
  title,
  type,
  isCandidate,
  candidateId,
  editSkillsModalOpen,
  setEditSkillsModalOpen,
  initialHardSkills,
  initialSoftSkills,
  hardSkills,
  softSkills,
  isEditable,
  onInit,
  style
}: any) => {
  const { NO_DATA } = getPlaceholders();
  const { t } = useLocales();
  const [initialSkills, setInitialSkills] = useState({
    hard_skills: {
      title: 'hard_skills',
      data: initialHardSkills.data
    },
    soft_skills: {
      title: 'soft_skills',
      data: initialSoftSkills.data
    }
  });
  const [skills, setSkills] = useState<Skills>({
    hard_skills: {
      title: 'hard_skills',
      data: []
    },
    soft_skills: {
      title: 'soft_skills',
      data: []
    }
  });

  const handleSave = async () => {
    if (isCandidate) {
      const requestBody = {
        hard_skills: skills.hard_skills.data,
        soft_skills: skills.soft_skills.data
      };

      try {
        const res = await updateDataByUrl(
          `/api/candidate/${candidateId}/`,
          requestBody
        );
        setInitialSkills({
          hard_skills: {
            title: 'hard_skills',
            data: res.hard_skills
          },
          soft_skills: {
            title: 'soft_skills',
            data: res.soft_skills
          }
        });
      } catch (error) {
        console.log(error);
        setEditSkillsModalOpen(false);
        document.body.style.overflow = '';
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = '';
  }, [initialHardSkills, initialSoftSkills]);

  useEffect(() => {
    setSkills(initialSkills);
  }, []);

  useEffect(() => {
    setSkills((prevSkills) => ({
      ...prevSkills,
      soft_skills: {
        title: 'soft_skills',
        data: mergeSkills(
          prevSkills.soft_skills.data,
          softSkills.data,
          initialSkills.soft_skills.data
        )
      }
    }));
  }, [softSkills]);

  useEffect(() => {
    setSkills((prevSkills) => ({
      ...prevSkills,
      hard_skills: {
        title: 'hard_skills',
        data: mergeSkills(
          prevSkills.hard_skills.data,
          hardSkills.data,
          initialSkills.hard_skills.data
        )
      }
    }));
  }, [hardSkills]);

  useEffect(() => {
    if (
      skills.hard_skills.data.length > 0 ||
      skills.soft_skills.data.length > 0
    ) {
      handleSave();
    }
  }, [skills]);

  const mergeSkills = (
    prev: string[],
    current: string[],
    initial: string[]
  ) => {
    const mergedSet = new Set([...initial, ...prev, ...current]);
    return Array.from(mergedSet);
  };

  const handleClose = () => {
    setEditSkillsModalOpen(false);
  };

  const handleSetValue = (title: keyof typeof skills, newValue: any) => {
    setSkills((prevSkills) => ({
      ...prevSkills,
      [title]: { ...prevSkills[title], data: newValue }
    }));
  };
  const skillsArray = [skills.hard_skills, skills.soft_skills];

  // eslint-disable-next-line consistent-return
  const skillColor = (skill: string) => {
    if (skill) {
      if (skills.hard_skills.data?.includes(skill)) {
        return initialHardSkills.data?.includes(skill) ? '#14a741' : '#1C9DD7';
      }

      if (skills.soft_skills.data?.includes(skill)) {
        return initialSoftSkills.data?.includes(skill) ? '#F4A261' : '#E76F51';
      }
    }
  };

  const getSkillSetTitle = (skillSet: string) =>
    skillSet.includes('soft') ? 'Soft skills' : 'Hard skills';

  return (
    <CandidateCardContainer
      title={title}
      withoutBox={false}
      onEdit={isEditable ? () => setEditSkillsModalOpen(true) : null}
      type={type}
      style={style}
    >
      <CandidateModalContainer open={editSkillsModalOpen} onClose={handleClose}>
        {isEditable && (
          <CardWrapper sx={{ boxShadow: 'none' }}>
            <Title1 mb="10px">{t('Skills')}</Title1>
            {skillsArray.map((skillSet, idx) => (
              <CustomAutocomplete
                key={idx}
                multiple
                id={`tags-filled-${idx}`}
                options={[]}
                value={skillSet.data || []}
                freeSolo
                onChange={(e: any, newValue: any) => {
                  handleSetValue(
                    skillSet.title as keyof typeof skills,
                    newValue
                  );
                }}
                sx={{
                  background: '#ffffff',
                  borderRadius: '10px',
                  mt: idx > 1 ? 4 : 2
                }}
                loadingText="loading"
                popupIcon={
                  <KeyboardArrowDownIcon style={{ color: '#562C82' }} />
                }
                renderTags={(value: string[], getTagProps: any) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    const backgroundColor = skillColor(option);
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                        style={{
                          color: 'white',
                          fontWeight: 500,
                          backgroundColor
                        }}
                      />
                    );
                  })
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={getSkillSetTitle(skillSet.title)}
                    placeholder="Start typing and press enter"
                  />
                )}
              />
            ))}
            <MButton
              title="Save my skills"
              MyClass="violetButton"
              style={{ width: '100%', marginTop: '25px', height: '48px' }}
              click={() => {
                handleSave();
                setEditSkillsModalOpen(false);
              }}
            />
          </CardWrapper>
        )}
      </CandidateModalContainer>

      <Box sx={{ width: '100%', display: 'flex', columnGap: '40px' }}>
        {skillsArray &&
        skillsArray.some((skillSet) => skillSet.data.length > 0) ? (
          <CardWrapper
            sx={{
              boxShadow: 'none',
              padding: '20px 0px'
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap'
              }}
            >
              {skillsArray.map((skillSet, idx) => (
                <Grid key={idx} mb={idx === 0 ? 2 : 0}>
                  <Typography mb="10px">
                    {getSkillSetTitle(skillSet.title)}
                  </Typography>
                  <Stack
                    spacing={1}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1,
                      flexWrap: 'wrap'
                    }}
                  >
                    {skillSet.data?.map((skill: string, idx: number) => (
                      <Chip
                        key={idx}
                        label={skill}
                        style={{
                          color: 'white',
                          fontWeight: 500,
                          backgroundColor:
                            skillSet.title === 'hard_skills'
                              ? '#14a741'
                              : '#F4A261',
                          margin: 0
                        }}
                      />
                    ))}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </CardWrapper>
        ) : (
          <EmptyContent title={NO_DATA} />
        )}
      </Box>
    </CandidateCardContainer>
  );
};
