import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Dialog,
  Avatar,
  DialogTitle,
  Card,
  CardContent
} from '@material-ui/core';

import { getComparedData } from 'helpers/listValues';
import { getCardValuesByType } from 'helpers/cardValues';
import { RootState } from 'redux/store';
import useLocales from 'hooks/useLocales';
import { getPlaceholders } from 'constants/text/placeholders';
import { Close, LegendToggle } from '@material-ui/icons';
import { PATH_DASHBOARD } from 'routes/paths';
import { Link as RouterLink, Link } from 'react-router-dom';
import { getButtons } from 'constants/text/buttons';
import { DEFAULT_MESSAGE_TEXTS, SALARY_CHOICES_INR } from 'constants/constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import useAuth from '@hooks/useAuth';
import { getCandidateRemind } from 'requests/candidate';
import { useSnackbar } from 'notistack';
import { postDataByUrl } from 'requests';
import domtoimage from 'dom-to-image';
import JsPDF from 'jspdf';
import axios from 'axios';
import { CandidateSkillsCard } from 'views/dashboard/candidateProfile/CandidateSkillsCard';
import ScoreField from './ScoreField';
import CultureProfileCharts, {
  ChartDescription,
  sortedPoint
} from './CultureProfileCharts';
import SvgIconStyle from './SvgIconStyle';
import { Description2, Title1, TitlePrimary } from './text/title';
import { ButtonPrimary } from './new-designer/button/ButtonPrimary';
import Markdown from './Markdown';
import AIModal from './AIModal';

const CTAButtonStyle = { width: '100%', my: 1, padding: '10px 0' };

const CandidateModal = ({
  open,
  handleClose,
  candidate,
  profile,
  cultureProfile,
  item
}: any) => {
  const application_id = candidate?.id || null;
  const { t } = useLocales();
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [base64Image, setBase64Image] = useState('');

  const { ANONYMOUS } = getPlaceholders();
  // const options = useSelector((state: RootState) => state.fixturesReducer);
  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const options = { ...opt };
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  if (company.currency == 'INR') {
    options.salaries = SALARY_CHOICES_INR;
  }
  const { ShOW_BEST_MATCHES } = getButtons();
  const { score, culture_score } = candidate;
  candidate = candidate?.candidate ? candidate.candidate : candidate;

  const candidateName = candidate.anonymous ? ANONYMOUS : candidate.name;
  const user = useSelector((state: RootState) => state.authJwt.user);
  const { enqueueSnackbar } = useSnackbar();
  const { isCandidate, isHiringManager } = useAuth();

  const componentRef = useRef(null);
  const descriptionRef = useRef(null);
  const chartsRef = useRef(null);

  const fields = getCardValuesByType(
    'candidate-modal-info',
    candidate,
    options,
    company.currency
  );

  const isRecruiterOrAdmin = useMemo(
    () => user.role === 'recruiter' || user.role === 'admin',
    [user?.role]
  );

  useEffect(() => {
    setBase64Image('');
    const getBase64 = async (url: string) => {
      try {
        const response = await axios.get(url, {
          responseType: 'blob'
        });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result as string;
          setBase64Image(base64);
        };
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    const candidateAvatar = candidate?.avatar || '';
    if (candidateAvatar) {
      getBase64(candidateAvatar);
    }
  }, [candidate?.avatar]);

  const matchCompared = getComparedData(
    candidate.competencies,
    profile.competencies
  );
  const cultureCompared = getComparedData(
    candidate.competencies,
    cultureProfile.competencies
  );

  const labels = cultureProfile.competency_labels;
  const points = cultureProfile.competency_min_scores;
  let sortedPoints: Array<sortedPoint>;
  if (!labels?.[0]?.id) {
    sortedPoints =
      labels
        ?.map((el: string, i: number) => ({
          point: points[i],
          label: el,
          descriptions: '',
          comparePoint: cultureCompared[i].score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labels.sort((a: any, b: any) => b.id - a.id).reverse();
    const sortedInPoints = points
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPoints =
      sortedLabels
        ?.map((el: any, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: '',
          comparePoint: cultureCompared[i]?.score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }

  const labelsProfile = profile.competency_labels;
  const pointsProfile = profile.competency_min_scores;
  let sortedPointsProfile: Array<sortedPoint>;
  if (!labelsProfile?.[0]?.id) {
    sortedPointsProfile =
      labelsProfile
        ?.map((el: string, i: number) => ({
          point: pointsProfile[i],
          label: el,
          descriptions: '',
          comparePoint: matchCompared[i].score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  } else {
    const sortedLabels = labelsProfile
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    const sortedInPoints = pointsProfile
      .sort((a: any, b: any) => b.id - a.id)
      .reverse();
    sortedPointsProfile =
      sortedLabels
        ?.map((el: any, i: number) => ({
          point: sortedInPoints[i].min_score,
          label: el.name,
          descriptions: '',
          comparePoint: matchCompared[i]?.score
        }))
        ?.sort((a: any, b: any) => b.point - a.point) || [];
  }
  const handleRemind = async () => {
    const res = await getCandidateRemind(item?.is);
    try {
      if (res) {
        enqueueSnackbar('Reminded successfully', { variant: 'success' });
      }
    } catch {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.error, { variant: 'error' });
    }
  };

  const pdf = new JsPDF('p', 'mm', 'a4');

  const expandDescription = async () => {
    setDescriptionExpanded(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  const handleGeneratePDFData = async () => {
    await expandDescription();
    const input1 = componentRef.current as HTMLDivElement | null;
    const input2 = chartsRef.current as HTMLDivElement | null;
    const input3 = descriptionRef.current as HTMLDivElement | null;

    const captureElement = async (element: HTMLDivElement | null) => {
      if (!element) return '';
      const originalHeight = element.style.height;
      const originalOverflow = element.style.overflow;

      element.style.height = 'auto';
      element.style.overflow = 'visible';

      const dataUrl = await domtoimage.toPng(element);

      element.style.height = originalHeight;
      element.style.overflow = originalOverflow;

      return dataUrl;
    };

    const imgData1 = await captureElement(input1);
    const imgData2 = await captureElement(input2);
    const imgData3 = await captureElement(input3);

    const imgWidth = 210;

    const imgHeight1 = input1
      ? (input1.offsetHeight * imgWidth) / input1.offsetWidth
      : 0;
    const imgHeight2 = input2
      ? (input2.offsetHeight * imgWidth) / input2.offsetWidth
      : 0;

    const imgHeight3 = input3
      ? (input3.offsetHeight * imgWidth) / input3.offsetWidth
      : 0;

    setDescriptionExpanded(false);

    return {
      imgData1,
      imgData2,
      imgData3,
      imgWidth,
      imgHeight1,
      imgHeight2,
      imgHeight3
    };
  };

  const generatePDFFile = async () => {
    const res = await handleGeneratePDFData();
    const {
      imgData1,
      imgData2,
      imgData3,
      imgWidth,
      imgHeight1,
      imgHeight2,
      imgHeight3
    } = res;

    pdf.addImage(
      imgData1,
      'PNG',
      0,
      0,
      imgWidth,
      imgHeight1,
      'alias1',
      'MEDIUM'
    );
    pdf.addPage();
    pdf.addImage(
      imgData2,
      'PNG',
      0,
      100,
      imgWidth,
      imgHeight2,
      'alias2',
      'MEDIUM'
    );
    pdf.addPage();
    pdf.addImage(
      imgData3,
      'PNG',
      0,
      0,
      imgWidth,
      imgHeight3,
      'alias3',
      'MEDIUM'
    );
  };

  const handleDownloadPDF = async () => {
    await generatePDFFile();
    pdf.save(`${candidateName}.pdf`);
    setDescriptionExpanded(false);
  };

  const handleUploadToATS = async () => {
    await generatePDFFile();
    const pdfData = pdf.output('arraybuffer');
    const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });

    const formData = new FormData();
    formData.append('application_id', application_id);
    formData.append('application_pdf', pdfBlob, 'application.pdf');

    try {
      const res = await postDataByUrl('/api/kombo/send_candidate/', formData);
      if (res) {
        enqueueSnackbar(
          res.message || t('Candidate is pushed to your ATS successfully'),
          {
            variant: 'success'
          }
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const chartDescriptionLabels = [sortedPointsProfile, sortedPoints];

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <Close
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          display: 'block',
          cursor: 'pointer',
          color: '#C9CAD9',
          fontSize: '30px'
        }}
      />
      <AIModal
        onClose={() => {
          setDescriptionExpanded(false);
        }}
        open={descriptionExpanded}
        customMessage={t('Work in progress!')}
      />
      <Grid
        ref={componentRef}
        container
        style={{
          justifyContent: 'space-between',
          padding: '20px',
          paddingTop: '80px'
        }}
      >
        <DialogTitle
          sx={{ maxWidth: 400, width: '100%', borderRadius: '21px' }}
          style={{
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px'
          }}
        >
          <Grid
            style={{
              background:
                'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
              boxShadow: '0px 0px 5.71474px rgba(0, 0, 0, 0.08)',
              height: '120px',
              borderRadius: '21px'
            }}
          />
          <Avatar
            sx={{ height: '90px', width: '90px' }}
            alt={candidateName}
            src={base64Image || ''}
            style={{
              margin: '-90px auto 0 auto',
              width: '142px',
              height: '142px',
              border: '6px solid #ffffff'
            }}
          />
          <CardContent
            style={{ textAlign: 'center', paddingRight: 0, paddingLeft: 0 }}
          >
            <Title1>{candidateName}</Title1>
            {/* <Typography variant="h4" color="GrayText" padding={0}>
            {profile.name}
          </Typography> */}
            <Description2>
              <Markdown source={candidate.pitch} />
            </Description2>
            {score || item?.score || culture_score || item?.culture_score ? (
              <>
                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      color: '#4cbbea',
                      columnGap: '10px',
                      width: '200px'
                    }}
                  >
                    <LegendToggle />
                    <ScoreField score={score || item?.score} type="talent" />
                  </Box>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      color: '#14a741',
                      columnGap: '10px',
                      width: '200px'
                    }}
                  >
                    <LegendToggle />
                    <ScoreField
                      score={culture_score || item?.culture_score}
                      type="culture"
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              !isCandidate && (
                <ButtonPrimary
                  style={{
                    width: '100%',
                    borderRadius: '30px',
                    cursor: 'pointer',
                    padding: '5px 15px',
                    marginTop: '20px'
                  }}
                  onClick={handleRemind}
                >
                  {t('Remind the candidate to take personality assessment')}
                </ButtonPrimary>
              )
            )}
            {!isCandidate && !descriptionExpanded && (
              <Grid>
                <Link
                  to={PATH_DASHBOARD.candidates.getCandidateProfileUrl(
                    candidate.id
                  )}
                  style={{ textDecoration: 'none' }}
                >
                  <ButtonPrimary sx={{ ...CTAButtonStyle, mt: 3, mb: 2 }}>
                    {t('View full profile')}
                  </ButtonPrimary>
                </Link>
                {!isHiringManager && (
                  <Grid
                    sx={{
                      width: '100%',
                      flexDirection: { xs: 'column', sm: 'row' },
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '16px',
                      alignItems: 'center'
                    }}
                  >
                    <ButtonPrimary
                      onClick={handleDownloadPDF}
                      style={CTAButtonStyle}
                    >
                      {t('Download')}
                    </ButtonPrimary>

                    <ButtonPrimary
                      onClick={handleUploadToATS}
                      style={CTAButtonStyle}
                    >
                      {t('Send to ATS')}
                    </ButtonPrimary>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
        </DialogTitle>
        <Grid
          style={{
            width: '100%',
            maxWidth: '480px'
          }}
        >
          <Grid
            style={{
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
              borderRadius: '20px',
              maxWidth: '550px',
              width: '100%',
              padding: '20px 28px',
              height: '100%'
            }}
          >
            <Title1
              style={{
                fontWeight: 600,
                fontSize: '20px',
                color: '#131417',
                marginBottom: '20px'
              }}
            >
              {t('Information')}
            </Title1>
            <Grid
              style={{
                rowGap: '48px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {fields.map((el: any, i: number) => (
                <Grid
                  item
                  xs={6}
                  key={i}
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    maxWidth: '100%'
                  }}
                >
                  <SvgIconStyle
                    src={el.image}
                    style={{ marginRight: '10px' }}
                  />
                  <TitlePrimary
                    style={{
                      width: '150px',
                      marginLeft: '10 px'
                    }}
                  >
                    {el.label}
                  </TitlePrimary>
                  <Description2
                    variant="body1"
                    style={{
                      width: '150px',
                      marginLeft: 'auto'
                    }}
                  >
                    {el.value}
                  </Description2>
                </Grid>
              ))}
              {!item &&
                isRecruiterOrAdmin &&
                !isCandidate &&
                !descriptionExpanded && (
                  <ButtonPrimary
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to={PATH_DASHBOARD.jobPosts.getBestMatchesJobUrl(
                      candidate?.id
                    )}
                  >
                    {ShOW_BEST_MATCHES}
                  </ButtonPrimary>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            minHeight: '100%',
            width: '100%',
            marginTop: '48px'
          }}
        >
          <CandidateSkillsCard
            title={t('Skills')}
            type="skills"
            isCandidate={false}
            candidateId={candidate.id}
            editSkillsModalOpen={false}
            setEditSkillsModalOpen={() => {}}
            initialHardSkills={{
              title: 'hard_skills',
              data: candidate.hard_skills || []
            }}
            initialSoftSkills={{
              title: 'soft_skills',
              data: candidate.soft_skills || []
            }}
            hardSkills={{ title: 'hard_skills', data: [] }}
            softSkills={{ title: 'soft_skills', data: [] }}
            isEditable={false}
            onInit={() => {}}
            style={{
              margin: 0,
              height: '100%',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)'
            }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid container spacing={2} sx={{ padding: '20px' }} ref={chartsRef}>
          <Grid item xs={12} sm={6}>
            <Card className="open-job-posts-card">
              <Box
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  color: '#4cbbea',
                  columnGap: '10px',
                  padding: '10px',
                  justifyContent: 'center'
                }}
              >
                <LegendToggle />
                <ScoreField score={score || item?.score} type="talent" />
              </Box>
            </Card>
            <Card className="open-job-posts-card">
              <CultureProfileCharts
                title={t('Match competencies')}
                labels={profile?.competency_labels}
                points={profile?.competency_min_scores}
                compareTitle={t('Candidate competencies')}
                comparePoints={matchCompared}
                descriptions={profile?.competencies?.map((el: any) => ({
                  description: el.competency.description,
                  name: el.competency.name
                }))}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className="open-job-posts-card">
              <Box
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  color: '#14a741',
                  columnGap: '10px',
                  justifyContent: 'center',
                  padding: '10px'
                }}
              >
                <LegendToggle />
                <ScoreField
                  score={culture_score || item?.culture_score}
                  type="culture"
                />
              </Box>
            </Card>
            <Card className="open-job-posts-card">
              <CultureProfileCharts
                title={t('Culture competencies')}
                labels={cultureProfile?.competency_labels}
                points={cultureProfile?.competency_min_scores}
                compareTitle={t('Candidate competencies')}
                comparePoints={cultureCompared}
                descriptions={cultureProfile?.competencies?.map((el: any) => ({
                  description: el.competency.description,
                  name: el.competency.name
                }))}
              />
            </Card>
          </Grid>
        </Grid>

        {/** */}
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            pt: descriptionExpanded ? '32px' : 0,
            px: '16px',
            pb: '16px',
            gap: '16px',
            justifyContent: 'space-between'
          }}
          ref={descriptionRef}
        >
          {chartDescriptionLabels.map((CDL, idx) => (
            <ChartDescription
              key={idx}
              labels={CDL}
              accordionExpanded={descriptionExpanded}
              style={{ width: '100%' }}
            />
          ))}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CandidateModal;
