import JsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { CompetencyByGroupTypeNL } from 'types/Assessment';
import { TeamCandidate } from './types';

export const getGroupKeys = (candidates: TeamCandidate[]): string[] => {
  if (!candidates || candidates.length === 0) return [];

  // Find the first candidate with competencies
  const candidateWithCompetencies = candidates.find(
    (candidate) =>
      candidate.competencies && Object.keys(candidate.competencies).length > 0
  );

  // If a candidate with competencies is found, return the keys
  if (candidateWithCompetencies && candidateWithCompetencies.competencies) {
    return Object.keys(candidateWithCompetencies.competencies);
  }

  // Otherwise, return an empty array
  return [];
};

export const generateDatasetForGroup = (
  candidates: TeamCandidate[],
  groupKey: keyof CompetencyByGroupTypeNL
) => {
  const labels = [] as string[];
  const comparePoints: number[][] = [];
  const candidateNames: string[] = [];
  const descriptions: string[] = [];

  candidates.forEach((candidate: TeamCandidate) => {
    if (candidate.competencies) {
      const group = (candidate.competencies as CompetencyByGroupTypeNL)[
        groupKey
      ];

      if (group && Array.isArray(group.lables) && Array.isArray(group.data)) {
        candidateNames.push(candidate.name);
        group.lables.forEach((label: string, index: number) => {
          if (!labels.includes(label)) {
            labels.push(label);
            descriptions.push(group.descriptions[index]);
          }
          comparePoints[index] = comparePoints[index] || [];
          comparePoints[index].push(group.data[index]);
        });
      }
      // else {
      //         console.warn(
      //           `Invalid group structure for candidate:`,
      //           candidate,
      //           `groupKey: ${groupKey}`
      //         );
      //       }
    }
    // else {
    //       console.warn(`Candidate has no competencies:`, candidate);
    //     }
  });

  // Calculate average points or other aggregated metrics
  const points = comparePoints.map(
    (values) => values.reduce((sum, val) => sum + val, 0) / values.length
  );

  return { points, labels, comparePoints, candidateNames, descriptions };
};

export const downloadTeamPDF = async (
  refs: (React.MutableRefObject<null> | undefined)[]
) => {
  const pdf = new JsPDF('p', 'mm', 'a4');
  const pageWidth = 210; // A4 width in mm
  const pageHeight = 297; // A4 height in mm

  if (refs) {
    const captureImages = await Promise.all(
      refs.map(async (ref, i) => {
        const element = ref?.current as HTMLDivElement | null;
        if (!element) {
          console.warn(`No valid element for ref ${i + 1}`);
          return null;
        }

        const originalHeight = element.style.height;
        const originalOverflow = element.style.overflow;

        element.style.height = 'auto';
        element.style.overflow = 'visible';

        try {
          const dataUrl = await domtoimage.toPng(element);
          let imgWidth = pageWidth;
          let imgHeight =
            (imgWidth * element.offsetHeight) / element.offsetWidth;

          if (imgHeight > pageHeight) {
            imgHeight = pageHeight;
            imgWidth = (element.offsetWidth * imgHeight) / element.offsetHeight;
          }

          return { dataUrl, imgWidth, imgHeight };
        } catch (error) {
          console.error(`Error capturing element ${i + 1}:`, error);
          return null;
        } finally {
          element.style.height = originalHeight;
          element.style.overflow = originalOverflow;
        }
      })
    );

    captureImages.forEach((image, i) => {
      if (!image) return;

      if (i > 0) pdf.addPage();

      pdf.addImage(
        image.dataUrl,
        'PNG',
        0,
        0,
        image.imgWidth,
        image.imgHeight,
        '',
        'MEDIUM'
      );
    });

    pdf.save('team-modal.pdf');
  }
};
